<template>
    <div id="wrapper" :class="{ toggled: showSidebar }">
        <sidebar></sidebar>
        <div id="content">
            <nav class="navbar navbar-light bg-light border-bottom navbar-expand">
                <button type="button" @click="toggleSidebar" class="btn btn-default">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="fa fa-bars fa-lg"></span>
                </button>

                <div class="collapse navbar-collapse">
                    <div class="mr-auto">
                        <h3>{{title}}</h3>
                    </div>
                    <ul class="navbar-nav mt-2">
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {{ profile.name }}
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                                <router-link class="dropdown-item" :to="{ name: 'UserProfile' }">Perfil</router-link>
                                <router-link class="dropdown-item" to="/change-password">{{ $t("root.change_password") }}</router-link>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="/logout"><i class="fa fa-sign-out-alt"></i> Logout</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
            <main role="main" class="container-fluid main-section">
                <router-view></router-view>
            </main>
        </div>
        <login-modal :show="showLoginModal"></login-modal>
    </div>
</template>

<style lang="scss"></style>
<script>
import Sidebar from "./components/Sidebar";
import {mapActions, mapGetters} from "vuex";
import LoginModal from "./components/LoginModal";

export default {
    components: {
        Sidebar,
        LoginModal,
        //VButton
    },
    data() {
        return {
            showSidebar: true,
        };
    },
    mounted() {
        this.loadProfileData();
        this.setTitle("")
    },
    computed: {
        ...mapGetters({
            showLoginModal: "showLoginModal",
            profile: "profile",
            title: "title",
        }),
    },
    methods: {
        ...mapActions({
            loadProfileData: "loadProfileData",
            setShowLoginModal: "setShowLoginModal",
            setShowLoginModalExpired: "setShowLoginModalExpired",
            setTitle: "setTitle",
        }),
        toggleSidebar() {
            this.showSidebar = !this.showSidebar;
        },
    },
};
</script>
