<template>
    <div id="sidebar-wrapper">
        <ul class="sidebar-nav" v-if="profile">
            <li class="sidebar-brand">
                <h3>
                    <router-link to="/">
                        <img src="/assets/images/logo-mitsubishi-fuso.png" style="max-height: 100px;" />
                    </router-link>
                </h3>
            </li>
<!--            <li :class="{ active: $route.name === 'Home' }">
                <router-link :to="{ name: 'Home' }"><i class="fa fa-home" style="margin-right: 5px"></i> Home</router-link>
            </li>-->
            <li v-if="hasPermission('UTILIZADORES_GERIR')" :class="{ active: $route.name === 'Users' }">
                <router-link :to="{ name: 'Users' }"><i class="fa fa-users-cog" style="margin-right: 5px"></i> Utilizadores</router-link>
            </li>
            <li v-if="hasPermission('UTILIZADORES_GERIR')" :class="{ active: $route.name === 'Perfis' }">
                <router-link :to="{ name: 'Perfis' }"><i class="fa fa-users-cog" style="margin-right: 5px"></i> Perfis</router-link>
            </li>
<!--            <li v-if="hasPermission('VIATURAS_CARREGAR')" :class="{ active: $route.name === 'CarregarStockFinanceiro' }">
                <router-link :to="{ name: 'CarregarStockFinanceiro' }"><i class="fa fa-upload" style="margin-right: 5px"></i>Carregar Stock</router-link>
            </li>-->
            <li v-if="hasPermission('VIATURAS_CARREGAR')" :class="{ active: $route.name === 'CarregarStockMarca' }">
                <router-link :to="{ name: 'CarregarStockMarca' }"><i class="fa fa-upload" style="margin-right: 5px"></i>Carregar Stock Marca</router-link>
            </li>
            <!--<li :class="{ active: $route.name === 'StockFinanceiro' }">
                <router-link :to="{ name: 'StockFinanceiro' }"><i class="fa fa-car-side" style="margin-right: 5px"></i>Stock Financeiro</router-link>
            </li>-->
            <li :class="{ active: $route.name === 'StockMarca' }">
                <router-link :to="{ name: 'StockMarca' }"><i class="fa fa-car-side" style="margin-right: 5px"></i>Stock Marca</router-link>
            </li>
        </ul>
    </div>
</template>
<script>
// import {getLanguageOptions} from "../../language-utils";
// import {i18n} from "../../main"
import {mapGetters} from "vuex";

export default {
    components: {},
    data() {
        return {
            // selectedMenu: '',
            // selected: i18n.locale,
            // languageOptions: getLanguageOptions(),
            // showAgencyMenu: false
        };
    },
    watch: {},
    mounted() {
        // ambas estas vars devem estar no main.scala.html
    },
    beforeDestroy() {},
    computed: {
        ...mapGetters({
            profile: "profile",
            isAdmin: "isAdmin",
        }),
    },
    methods: {
        hasPermission(perm) {
            if (this.profile && this.profile.permissions) {
                const result = this.profile.permissions.includes(perm);
                return result;
            } else {
                return true;
            }
        },
        hasPermissionMatchStart(perm) {
            if (this.profile && this.profile.permissions) {
                const result = this.profile.permissions.filter((p) => {
                    return p.startsWith(perm);
                });
                return result.length > 0;
            } else {
                return true;
            }
        },
        hasPermissionMatchEnd(perm) {
            if (this.profile && this.profile.permissions) {
                const result = this.profile.permissions.filter((p) => {
                    return p.endsWith(perm);
                });
                return result.length > 0;
            } else {
                return true;
            }
        },
    },
};
</script>
