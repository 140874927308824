import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: { path: "/stock-marca" }, // redirect property
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: () =>
      import(/* webpackChunkName: "change-password" */ "../views/ChangePassword.vue")
  },
  {
    path: "/user-profile",
    name: "UserProfile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/UserProfile.vue")
  },
  {
    path: "/users",
    name: "Users",
    component: () =>
      import(/* webpackChunkName: "user-list" */ "../views/users/UserList.vue")
  },
  {
    path: "/perfis",
    name: "Perfis",
    component: () =>
      import(/* webpackChunkName: "user-list" */ "../views/users/PerfisList.vue")
  },
  {
    path: "/criar-perfil",
    name: "PerfilCriar",
    component: () => import(/* webpackChunkName: "users" */ "../views/users/PerfilCriar.vue"),
  },
  {
    path: "/editar-perfil/:id",
    name: "PerfilEditar",
    props: true,
    component: () => import(/* webpackChunkName: "users" */ "../views/users/PerfilEditar.vue"),
  },
  {
    path: "/user-invite",
    name: "UserInvite",
    component: () =>
      import(/* webpackChunkName: "user-invite" */ "../views/users/UserInvite.vue")
  },
  {
    path: "/user-invite-bulk",
    name: "UserInviteBulk",
    component: () =>
      import(/* webpackChunkName: "user-invite" */ "../views/users/UserInviteBulk.vue")
  },
  {
    path: "/carregar-stock-marca",
    name: "CarregarStockMarca",
    component: () =>
      import(/* webpackChunkName: "viaturas" */ "../views/viaturas/CarregarStockMarca.vue")
  },
  {
    path: "/carregar-stock-financeiro",
    name: "CarregarStockFinanceiro",
    component: () =>
      import(/* webpackChunkName: "viaturas" */ "../views/viaturas/CarregarStockFinanceiro.vue")
  },
  {
    path: "/stock-financeiro",
    name: "StockFinanceiro",
    component: () =>
      import(/* webpackChunkName: "stock" */ "../views/viaturas/StockFinanceiro.vue")
  },
  {
    path: "/stock-marca",
    name: "StockMarca",
    component: () =>
      import(/* webpackChunkName: "stock" */ "../views/viaturas/StockMarca.vue")
  },
];

const router = new VueRouter({
  routes
});

export default router;
