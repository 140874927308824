<template>
    <button :disabled="loading" @click="$emit('click', $event)">
        <slot v-if="!loading"></slot>
        <span v-else><i class="fas fa-spinner fa-spin"></i> {{text}}</span>
    </button>
</template>

<script>
    export default {
        name: "VButton",
        props: {
            loading: Boolean,
            text: {
                type: String,
                default: ""
            }
        },
        methods: {
        }
    }
</script>

<style scoped>

</style>
